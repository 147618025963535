@import "../../AppStyles/index";

.Overview {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $colour2;
}

.Avatar {
  width: 300px;
}

.TechHighlights {
  @include tilted-bg($colour-white-dark);

  display: flex;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-top: 8px;
  padding: 8px 24px;
  gap: 16px;
}

.ScrollDown {
  position: absolute;
  bottom: 10px;
  height: 25px;
}
