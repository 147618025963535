@import "../AppStyles/index";

.PageFooter {
  display: flex;
  padding: 24px;
  color: $colour-white;
  background-color: $colour4;

  a {
    color: $colour-white;
  }

  .FooterLeft {
    width: 33%;
    text-align: left;
  }

  .FooterCenter {
    width: 33%;
    text-align: center;
  }

  .FooterRight {
    width: 33%;
    text-align: right;
  }
}
