@import "../AppStyles/index";

.PageHeader {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: flex-start;

  width: 100%;
  max-width: 1024px;
  margin: auto;

  padding-top: 16px;

  transition: opacity 1s;
  opacity: 0;

  &.scrolled {
    opacity: 100%;
  }

  @media screen and (max-width: $screen-size-sm) {
    opacity: 0 !important;
  }
}
