@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Roboto&display=swap');

$sidebar-width: 200px;

$screen-size-sm: 800px;

$font-display: "gruppo", sans-serif;
$font-body:    "roboto", sans-serif;

$colour1:      #F6B149;
$colour1-dark: darken($colour1, 10%);

$colour2:      #F8572D;
$colour2-dark: darken($colour2, 10%);

$colour3:      #DF2A33;
$colour3-dark: darken($colour3, 10%);

$colour4:      #A22543;
$colour4-dark: darken($colour4, 10%);

$colour5:      #6B312D;
$colour5-dark: darken($colour5, 10%);

$colour-white:      #FCFCFC;
$colour-white-dark: darken($colour-white, 10%);
$colour-black:      #1E1E1E;
$colour-black-dark: darken($colour-black, 10%);

$colour-header: $colour3;
$colour-sidebar: $colour1;
