@import "../AppStyles/index";

.Technologies {

}

.List {
  display: flex;
  flex-wrap: wrap;
}

.ListItem {
  flex-basis: 33%;
  padding: 4px;
  display: inline-block;
}
