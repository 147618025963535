@import "../AppStyles/index";

.Interests {

}

.List {
  display: flex;
  flex-wrap: wrap;
}

.ListItem {
  display: inline-block;
  padding: 4px;
}
