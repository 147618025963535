@import "AppStyles/index";

* {
  box-sizing: border-box;
  font-family: $font-body;
}

body {
  margin: 0;
}
