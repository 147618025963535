@import "../AppStyles/index";

.Page {
  width: 100%;

  main {
    margin: auto;
    max-width: 1024px;
  }
}
