@import "../AppStyles/index";

.Header {
  @include tilted-bg($colour3);
  font-family: $font-display;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 16px;
  color: $colour-white;
}
