@import "../AppStyles/index";

.NamePlate {
  @include tilted-bg($colour1);

  font-family: $font-display;
  font-size: 40px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 8px 16px 16px;

  .subtitle {
    font-family: $font-display;
    font-size: 20px;
    font-weight: bold;
  }
}
