@import "../../AppStyles/index";

.Projects {
  background-color: $colour-white-dark;
}

.Project {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  .Title {
    @include tilted-bg($colour4);
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    padding: 8px;
    color: $colour-white;
  }

  .Image {
    display: inline-block;
    width: 50%;
    padding-top: 16px;

    img {
      width: 100%;
      border-radius: 4px;
    }

    @media screen and (max-width: $screen-size-sm) {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: auto;
        max-width: 100%;
        max-height: 200px;
      }
    }
  }

  .Description {
    display: inline-block;
    width: 50%;
    padding-left: 16px;

    @media screen and (max-width: $screen-size-sm) {
      width: 100%;
      padding-left: 0;
    }
  }
}
