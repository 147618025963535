@import "../AppStyles/index";

.ProfilePicture {
  @include tilted-bg($colour3);
  position: relative;

  z-index: 10;

  display: flex;

  justify-content: center;
  margin-bottom: 10px;

  margin-left: 50px;
  padding: 16px;
  border-radius: 12px;

  img {
    width: 100%;
  }
}
