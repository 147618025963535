@import "../AppStyles/index";

.Experience {
  background-color: $colour-white-dark;

  .Placements {
    margin-top: 24px;
    display: flex;
    gap: 16px;

    @media screen and (max-width: $screen-size-sm) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .Placement {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .PlacementHeader {
      @include tilted-bg($colour4);
      width: 100%;
      margin-bottom: 8px;
      padding: 4px 8px;
      color: $colour-white;

    }

    .Company {
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
    }

    .Duration {
      display: inline-block;
      margin-left: 8px;
    }

    .Title {
      border-bottom: 4px solid $colour4;
      padding: 4px;
      font-size: 14px;
      font-style: italic;
      width: 100%;
    }

    .Icons {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .Technologies {
      width: 120px;

      .Item {
        margin-top: 4px;
      }
    }

    .Tasks {
      width: calc(100% - 120px);
      padding-left: 8px;

      .Item {
        $bullet-size: 12px;
        position: relative;
        margin-top: 4px;
        padding-left: $bullet-size;

        &::before {
          position: absolute;
          left: -$bullet-size;
          content: "➔"
        }
      }
    }
  }
}
