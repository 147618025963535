@import "variables";

@mixin tilted-bg($colour, $drop-shadow: true) {
  position: relative;
  z-index: 0;

  &::before {
    content: '';

    background-color: $colour;

    @if $drop-shadow {
      box-shadow: 5px 5px 0 darken($colour, 10%);
    }

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: -10;

    transform: skewX(-10deg);
  }
}

@mixin section-header {
  @include tilted-bg($colour3)
}
