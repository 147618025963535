@import "../AppStyles/index";

.PageMain {

}

.col1 {
  display: inline-block;
  width: 33%;
}

.col2 {
  display: inline-block;
  width: 66%;
}
